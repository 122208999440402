.input input::-webkit-outer-spin-button,
.input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
