.button {
    height: 34px;
    width: 38px;
    padding: 6px;

    position: relative;
    cursor: pointer;
}
.button span {
    cursor: pointer;
    height: 2px;
    width: 22px;
    border-radius: 2px;
    display: block;
    content: '';
}
