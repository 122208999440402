.focused-container {
    background:
        linear-gradient(
                to right,
                currentcolor var(--lineWidth),
                transparent var(--lineWidth)
            )
            0 0,
        linear-gradient(
                to right,
                currentcolor var(--lineWidth),
                transparent var(--lineWidth)
            )
            0 100%,
        linear-gradient(
                to left,
                currentcolor var(--lineWidth),
                transparent var(--lineWidth)
            )
            100% 0,
        linear-gradient(
                to left,
                currentcolor var(--lineWidth),
                transparent var(--lineWidth)
            )
            100% 100%,
        linear-gradient(
                to bottom,
                currentcolor var(--lineWidth),
                transparent var(--lineWidth)
            )
            0 0,
        linear-gradient(
                to bottom,
                currentcolor var(--lineWidth),
                transparent var(--lineWidth)
            )
            100% 0,
        linear-gradient(
                to top,
                currentcolor var(--lineWidth),
                transparent var(--lineWidth)
            )
            0 100%,
        linear-gradient(
                to top,
                currentcolor var(--lineWidth),
                transparent var(--lineWidth)
            )
            100% 100%;
    background-repeat: no-repeat;
    background-size: var(--corner-size) var(--corner-size);
}
